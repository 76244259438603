@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  width: 100%;
  background: #f0f5f8;
}

html,
body #root {
  min-height: 100vh;
  background: #f0f5f8;
}
